import { graphql } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useRef } from "react";
import { StructuredText } from "react-datocms";
import Clients from "../components/Clients";
import Features from "../components/Features";
import Highlight from "../components/Highlight";
import Layout from "../components/layout";
import theme from "../styles/theme";
import Hls from "hls.js";

// Todo: Points/features query dont work??

const ProductPage = ({ location, data }) => {
  const videoRef = useRef(null);
  const src = data.product.video?.video.streamingUrl;
  const poster = data.product.leadImage.resolutions.src;

  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  const highlightData = {
    referenceLogo: data.product.referenceLogo,
    reference: data.product.reference,
    referenceImage: data.product.referenceImage,
    backgroundImage: data.product.backgroundImage,
    backgroundLayer: data.product.backgroundLayer,
  };
  const featuresData = {
    features: data.product.features,
    featuresItems: data.product.points,
  };

  return (
    <Layout
      location={location}
      seo={data.product.seoMetaTags}
      logo={data.global.logo}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 200px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 120px;
          ${theme.tablet} {
            padding-top: 80px;
            padding-bottom: 0;
          }
        `}
      >
        <section
          css={`
            display: flex;

            margin-bottom: 100px;
            ${theme.mobile} {
              margin-bottom: 0;
            }
            h1 {
              margin-top: 100px;
              font-size: 64px;
              margin-bottom: 20px;
              ${theme.laptop} {
                font-size: 58px;
                margin-top: 80px;
              }
              ${theme.tablet} {
                margin-top: 10px;
              }
              ${theme.mobile} {
                font-size: 36px;
                margin-top: 10px;
              }
            }
            h2 {
              margin-top: 50px;
              line-height: 110%;
            }
            p {
              font-size: 18px;
              line-height: 120%;
              ${theme.mobile} {
                font-size: 18px;
              }
            }
            a {
              color: #e00084;
              opacity: 0.7;
              font-weight: 700;
              transition: 0.2s;
              &:hover {
                opacity: 1;
              }
            }
            > div {
              display: flex;
              flex-direction: column;
              flex: 1;
              flex-basis: 50%;
            }
            .heroLeft {
              max-width: 600px;
              ${theme.mobile} {
                margin-bottom: 100px;
              }
            }
            .heroRight {
              margin-left: auto;
              max-width: 800px;

              .img {
                margin-top: 100px;
                z-index: 10;
              }
              ${theme.mobile} {
                margin-left: -20px !important;
                margin-right: -20px !important;
                flex-basis: 100%;
                .img {
                  margin-top: 0;
                }
              }
            }
            ${theme.tablet} {
              flex-wrap: wrap-reverse;
              .heroRight {
                margin-right: auto;
              }
              video {
                max-width: 600px;
                margin: 0 auto;
              }
            }
            video {
              width: 100%;
            }
          `}
        >
          <div className="heroLeft">
            <h1>{data.product.title}</h1>
            <StructuredText
              data={data.product.lead}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsImage":
                    return (
                      <div>
                        <img src={record.img.url} alt={record.img.alt} />
                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />
          </div>
          <div className="heroRight">
            <video
              width="100%"
              height="auto"
              autoPlay={true}
              controls={false}
              poster={poster}
              muted
              playsInline
              loop
              ref={videoRef}
            />
          </div>
        </section>
        <Features data={featuresData} />
        <section
          css={`
            flex-direction: column;
            margin-top: 160px;
            margin-bottom: 180px;
            ${theme.mobile} {
              margin-top: 100px;
              margin-bottom: 80px;
            }
            padding-left: 0;
            padding-right: 0;
            > div:nth-child(even) {
              flex-direction: row-reverse;
            }
          `}
        >
          {data.product.arguments.map(item => {
            return (
              <div
                css={`
                  display: flex;
                  ${theme.max1600} {
                    padding-left: 0;
                    padding-right: 0;
                  }
                  flex-wrap: wrap;
                  > div {
                    flex: 1;
                  }
                  .img {
                    width: 100%;
                    height: 100%;
                    img {
                      width: 100%;
                      object-fit: cover;
                      object-position: 50% 50%;
                    }
                  }
                  .imgBox {
                    max-height: 420px;

                    ${theme.mobile} {
                      display: contents;
                      max-height: 200px;
                    }
                  }
                  a {
                    color: #e00084;
                    opacity: 0.7;
                    font-weight: 700;
                    transition: 0.2s;
                    &:hover {
                      opacity: 1;
                    }
                  }
                  .content {
                    padding-left: 0;
                    flex: 1;
                    display: flex;
                    padding-top: 70px;
                    padding-bottom: 70px;
                    flex-direction: column;
                    justify-content: center;
                    ${theme.mobile} {
                      flex-basis: 100%;
                      padding-top: 20px;
                      h3,
                      p {
                        padding-left: 20px !important;
                        padding-right: 20px !important;
                      }
                    }
                    h3 {
                      margin: 0;
                      font-size: 42px;
                      line-height: 110%;
                      color: ${theme.pink};
                      font-weight: 900;
                      ${theme.tablet} {
                        font-size: 32px;
                      }
                    }
                    h3,
                    p {
                      padding-left: 100px;
                      padding-right: 100px;
                      ${theme.tablet} {
                        padding-left: 50px;
                        padding-right: 50px;
                      }
                    }
                    .button {
                      margin-top: 20px;
                      color: ${theme.pink};
                    }
                  }
                `}
              >
                <div className="imgBox">
                  <Img
                    className="img"
                    fluid={item.img.fluid}
                    alt={item.img.alt}
                  />
                </div>
                <div className="content">
                  <h3>{item.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </div>
            );
          })}
        </section>
        <Highlight data={highlightData} />
        <Clients dataTitle={data.clientsTitle} dataClients={data.clients} />
      </main>
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query ProductQuery($slug: String!) {
    product: datoCmsProduct(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      leadImage {
        resolutions(width: 720) {
          src
        }
      }
      video {
        video {
          streamingUrl
          thumbnailUrl
        }
      }
      lead {
        value
        blocks {
          ... on DatoCmsImage {
            __typename
            id: originalId
            img {
              url
              alt
            }
          }
        }
      }
      features {
        value
        blocks {
          ... on DatoCmsButton {
            __typename
            id: originalId
            url
            text
            layout
          }
        }
      }
      features {
        value
      }
      points {
        title
        content
        icon {
          url
          alt
        }
      }
      arguments {
        ... on DatoCmsArgument {
          title
          content
          img {
            fluid(maxWidth: 720) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      referenceLogo {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      reference
      referenceImage {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      backgroundImage {
        fluid(maxWidth: 1400) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      backgroundLayer {
        red
        green
        blue
        alpha
      }
    }

    industries: allDatoCmsIndustry(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          text
          slug
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }

    clients: allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          text
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    global: datoCmsGlobal {
      allInOneTitle
      allInOneExcerpt
      cloudIcon {
        url
        alt
      }
      products {
        ... on DatoCmsProductItem {
          title
          content
          linkToPage {
            slug
          }
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      people {
        ... on DatoCmsPerson {
          content
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      partners {
        ... on DatoCmsPartner {
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
